.ModelCloseButton{
    float: right;
    margin-top: 10px;
    margin-bottom: 5px;
    margin-right: 15px;
}
.kep-login-facebook.medium{
    border-radius:30px ;
    padding: 5px;
    margin-bottom: 5px;
    margin-top: 5px;
}