#login_card {
    padding: 0 !important;
    border: 0 !important;
    margin: 0 !important;
    width: auto;
    align-items: center;

}

/*.modal-body {*/
/*    padding: 0px !important;*/

/*}*/

.modal-content.containerlogin {
    padding: 0px !important;
    border: 0px !important;
    margin: 0px !important;
}

.mes {
    width: 400px !important;
}

@media screen and (max-width: 440px) {
    .mes {
        width: auto !important;
    }
}

@media screen and (max-width: 991px) {
    .messs {
        margin-top: -10px;
    }
}

.dsali:hover {
    border-color: white !important;
    color: white !important;
}

.dsali {
    color: gray !important;
    border-color: gray !important;
}

.messss {
    color: white !important;
}

.jesss {
    margin-top: 25px;
}

.optsmes {
    border-radius: 0px !important;
    max-width: 400px;
}

.change-input-sign {
    -webkit-text-fill-color: black !important;
    box-shadow: 0 0 0 30px gray inset !important;
    transition: all .2s linear;
    background: rgb(232, 240, 254) !important;
}

.change-input-sign:focus {
    box-shadow: none;
}

.change-input-sign label {
    color: white !important;
}