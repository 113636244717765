.header-content{
    padding: 50px 0px 10px 0px;
    display: flow-root;
    border-bottom: 1px solid var(--divider);
    --divider: #67637033;
  }
  @media screen and (max-width:356px){
    .header-content{
      padding: 75px 0px 10px 0px;
    }
}
.left-contents{
    float: left;
    position: relative;
    left: 40px;
    margin: 3px 0px;
}
.left-nav{
    display: inline-block;
    padding: 5px !important;
    font-weight: 600;
    color: #0d0d17;
    text-decoration: none;
    cursor: pointer;
}
.left-nav:hover{
    color: purple;
}
.active-link-blog{
    position: absolute;
    bottom: -15px;
    left: 0;
    right: 0;
    border-bottom: 4px solid var(--primary);
    border-radius: 10px;
    z-index: 10;
    --primary: #FF2D55;
    width: 95px;
}
.right-contents {
    float: right;
    position: relative;
}
@media screen and (max-width:440px){
    .right-contents{
        width: 50%;
    }
}
@media screen and (max-width:340px){
    .right-contents{
        margin: 30px 0px 0px 0px  ;
        width: auto;
    }
}