.blog1{
    text-align: left;
}
.blog1 .blog-h4{
    padding: 15px !important;
}
.blog1 .blog_body{
    text-align: justify !important;
}
.container-blog{
    max-width: 680px;
    justify-content: center;
    display: flex;
    margin: 0px 24px;
    padding-bottom: 20px;
}
.parent-container{
    display: flex;
    justify-content: center;
}
.blog1 .blog-h1{
    font-size: 40px;
    font-weight: 600;
    text-align: left;
}
.name-blog{
    text-align: left;
    font-weight: 600;
}
.date-blog{
    color: grey;
    text-align: left;
    font-size: 13px;
}
.date-blog p{
    margin-top: -17px;
}
.blog-img{
    width: 680px;
}
.afterimg-blog {
    margin-top: 3%;
}
@media only screen and (max-width:727px){
    .blog1 .container-blog{
        width: 100%;
        margin: 0 24px;
    }
    .blog-img{
        width: 100%;
    }
}
.textme-blogme{
    margin-left: 50px;
}
.tagsblog11{
    position: relative;
    padding: 5px;
    margin: 0px 0px 13px 20px;
    border: 0px;
    border-radius: 30px;
    width: 102px;
    text-align: center;
    float: left;
    background-color: rgb(190, 190, 190 , 33%);
}
.tagsblog111{
    position: relative;
    padding: 5px;
    margin: 0px 0px 13px 49px;
    border: 0px;
    border-radius: 30px;
    width: 102px;
    text-align: center;
    float: left;
    background-color: rgb(190, 190, 190 , 33%);
}
.tagsblog112{
    position: relative;
    padding: 5px;
    margin: 0px 0px 13px 20px;
    border: 0px;
    border-radius: 30px;
    width: 132px;
    text-align: center;
    float: left;
    background-color: rgb(190, 190, 190 , 33%);
} 
.descblog1{
    margin-top: -10px;
    position: relative;
    display: flex;
    text-align: left;
    padding: 10px 0px 0px 45px;
    margin-bottom: 0.6rem;
}
.linkblog1{
    position: relative;
    display: flex;
    padding: 0px 0px 0px 30px;
    margin: -12px 0px 0px 0px;
    font-size: 1.4rem;
    color: #721D85;
    top: -1px;
}