.searchInputs {
  margin-top: 105px;
  display: flex;
}
input:focus {
  outline: none;
}
.dataResult {
  margin-top: 5px;
  width: 275px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 10px 10px 10px 10px;
  overflow: hidden;
  overflow-y: auto;
  position: absolute;
} 
.dataResult::-webkit-scrollbar {
  display: none;
}
.dataResult .dataItem {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  color: black;
}
  
.dataItem p {
  margin-left: 10px;
}
a {
  text-decoration: none;
}
  
.search a:hover {
  background-color: lightgrey;
}
  
#clearBtn {
  cursor: pointer;
}
.search-boxs{
  display: flex;
  align-items: center;
}
.search-imgs{
  z-index: 5;
  position: absolute;
  left: 11px;
}
.search-styles{
  padding: 7px 20px 7px 48px;
  border-radius: 20px;
}
.marginsvgme{
  margin: 0px -35px 0px 13px;
  z-index: 1;
}