/*****************************
*	vertical news ticker with image
******************************/
.ticker-wrapper-v-image{
	display: flex;	
	position: relative;
	width: 100%;
	height: 300px;
	overflow: hidden;
}
.li_newsbar{
	margin-right: 5% !important;
}
.news-ticker-v-image{
	list-style: none;
	margin:0;
	padding: 0;
	animation: tic-v-image 20s cubic-bezier(1, 0, .5, 0) infinite;
} 
.rowsss{
	display: flex !important;
	justify-content: start !important;
	margin-top: 20px;
}
.rowssss{
	display: contents !important;
}
.margi{
	margin-top: 5% !important;
}
.margil{
	margin-left: -12px;
}
.moth{
height: 50px;
}
.news-ticker-v-image:hover { 
	animation-play-state: paused; 
}

.news-ticker-v-image li{
	margin-bottom: 20px;
}
pre#test {
	white-space: normal;
	
  }	
.news-ticker-v-image .thumbnail {
	max-width: 100%;
	height: auto;
	float: left;
	margin-right: 10px;
	display: inline-block;
}
.ticker-wrapper-v-image .clear {
	clear: both;
}
.ticker-wrapper-v-image .news_title a{
	color: #721d85;
	font-size: 20px;
	font-weight: bold;
}
.ticker-wrapper-v-image .news_title{
	line-height: 20px;
}
.ticker-wrapper-v-image .news-content{
	color: #1d1d1d;
	font-size: 16px;
}

@keyframes tic-v-image {
	0%   {margin-top: 0;}
	25%  {margin-top: -16%;}
	50%  {margin-top: -32%;}
	75%  {margin-top: -50%;}
	100% {margin-top: 0;}
}

/*****************************
*	horizontal news ticker
******************************/

.ticker-wrapper-h{
	display: flex;	
	position: relative;
	overflow: hidden;
	border: 1px solid #721d85;
}

.ticker-wrapper-h .heading{
	background-color: #721d85;
	color: #fff;
	padding: 5px 10px;
	flex: 0 0 auto;
	z-index: 2;
}
.ticker-wrapper-h .heading:after{
	content: "";
	position: absolute;
	top: 0;
	border-left: 20px solid #721d85;
	border-top: 17px solid transparent;
	border-bottom: 15px solid transparent;
}


.news-ticker-h{
	display: flex;
	margin:0;
	padding: 0;
	padding-left: 90%;
	z-index: 1;
	
	animation-iteration-count: infinite;
	animation-timing-function: linear;
	animation-name: tic-h;
	animation-duration: 30s;
	
}
.news-ticker-h:hover { 
	animation-play-state: paused; 
}

.news-ticker-h li{
	display: flex;
	width: 100%;
	align-items: center;
	white-space: nowrap;
	padding-left: 20px;
}

.news-ticker-h li a{
	color: #78c7d2;
	font-weight: bold;
}

@keyframes tic-h {
	0% {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
		visibility: visible;
	}
	100% {
		-webkit-transform: translate3d(-100%, 0, 0);
		transform: translate3d(-100%, 0, 0);
	}
}
.react-multi-carousel-item {
	height: 100%;
}
.h2customi{
	font-size: 1.9rem !important;
}
.commu-social{
    color: #721d85;
    display: inline-flex;
    flex-direction: row;
    font-size: 30px;
	padding: 0px 10px 0px 0px;
}
.div-heights{
	height: 70px;
	display: flex;
	flex-direction: row;
	top: 30px;
    position: relative;
}
