/* Container needed to position the button. Adjust the width as needed */
.homepage_title {
    font-weight: bold;
    font-family: "Arial", sans-serif;
    padding-top: 20px;
}
.homepage_log_title_h3 {
    color: gray !important;
    font-weight: bold !important;
    font-family: "Arial", sans-serif;
    font-size: 16px;
    padding-bottom:0px ;

}

.homepage_log_setting .logo_image {
    width: auto;
    height: 60px;
    border-radius: 1px;
}

.homepage_log_setting {
    padding: 10px;
    align-items: center;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    align-content: center;
    justify-content: center;
}
.homepage_info_image{
    border-radius: 25px;
    object-fit: fill;
    width: 100%;
    height: 40vh;
}
.homepage_info_image_index1{
    border-radius: 25px;
    object-fit: cover;
    width: 100%;
    height: 40vh;
}
@media (max-width: 1200px) {
    .homepage_info_image, .homepage_info_image_index1 {

    }
}

@media (max-width: 992px) {
    .homepage_info_image, .homepage_info_image_index1 {

        object-fit: cover;
    }
}

@media (max-width: 768px) {
    .homepage_info_image, .homepage_info_image_index1 {
        object-fit: fill;
    }
}

@media (max-width: 576px) {
    .homepage_info_image, .homepage_info_image_index1 {

        object-fit: fill;
    }
}

.homepage_info_row{
    padding: 0px;
    margin: 10px 0px;
    justify-content: center;
    align-items: center;
}
.homepage_info_text{
    text-align: justify;
    font-size: x-large;
    font-family: "sofia-pro", sans-serif;
}
.book_demo_banner{
    margin-bottom: 25px;

}
.Book_demo_button {
    flex: 1 1 auto;
    text-align: center;
    border-radius: 40px;
    font-family: "Sofia Pro",sans-serif;
    border: none;
    cursor: pointer;
    text-transform: uppercase;
    outline: none;
    overflow: hidden;
    position: relative;
    color: black;
    font-weight: 700;
    font-size: 18px;
    background: #78c7d2;
    padding:5px 20px;
    margin:  10px;
    width:max-content;
    /*box-shadow: 0 0.313rem 0.938rem #0003;*/
    z-index: 3;
}
.Book_demo_button:hover{
    background: #0c5460;
    color: white;
}
.Book_demo_button::after{

    left: 0;
    top: 0;
    height: 490%;
    width: 140%;
    background-color: white;

    -webkit-transition: all .5s ease-in-out;
    transition: all .5s ease-in-out;
    -webkit-transform: translateX(-98%) translateY(-25%) rotate(45deg);
    transform: translateX(-98%) translateY(-25%) rotate(45deg);

}
.Book_demo_button:hover::after{
    -webkit-transform: translateX(-9%) translateY(-25%) rotate(45deg);
    transform: translateX(-9%) translateY(-25%) rotate(45deg);
}

.Book_demo_banner_text{
    font-size: x-large;
    font-family: "sofia-pro", sans-serif;
}
.collaborator_section{
    display: inline-block;
    transform: scale(1);
    transition: 0.3s;
    text-decoration: transparent;
}
.items-threebox {
    grid-column-end: span 4;
    display: flex;
    flex-direction: column;
    background-color: #39393b;
    cursor: pointer;
    transition: all 0.3s ease 0s;
    border-radius: 25px;
}



.textili-1 {
    right: 110px;
}

.textili-2 {
    right: 100px;
}

.textili-3 {
    right: 110px;
}

.textili-4 {
    right: 35px;
}

.explore-waitinglist {
    background: #78c7d2;
}

.btn-setup a {
    text-decoration: none !important;
}

.btn-setup {
    position: absolute;
}

.btn-mother {
    position: absolute;
    left: 90px;
    top: 470px;
    width: 595px;
    /* z-index: 0; */
    text-align: left;
    font-family: Sofia Pro, sans-serif;
    FONT-VARIANT: JIS04;
    z-index: 3;
    color: #fff;
    font-size: 3rem;
    font-weight: bold;
    display: block;
    text-shadow: 0 5px 10px rgb(0 0 0 / 25%);
}

/*.btn-note{

}*/
.morse-note {
    font-family: Sofia Pro, sans-serif !important;
    FONT-VARIANT: JIS04 !important;
    z-index: 3 !important;
    color: #fff !important;
    font-size: 1.3rem !important;
    font-weight: bold !important;
    display: block !important;
    text-shadow: 0 5px 10px rgb(0 0 0 / 25%) !important;
}

/*.btn-desc{

}*/

.morse-desc {
    font-family: Sofia Pro, sans-serif !important;
    FONT-VARIANT: JIS04 !important;
    z-index: 3 !important;
    color: #fff !important;
    font-size: 3rem !important;
    font-weight: bold !important;
    display: block !important;
    text-shadow: 0 5px 10px rgb(0 0 0 / 25%) !important;
}

@media only screen and (max-width: 795px) {
    .btn-mother {
        width: 470px !important;
        top: 525px !important;
    }

    .morse-desc {
        font-size: 2rem !important;
    }

    .morse-note {
        font-size: 1rem !important;
    }
}

@media only screen and (max-width: 672px) {
    .btn-mother {
        left: 20px !important;
    }
}

@media only screen and (max-width: 525px) {
    .btn-video {
        padding: 0.463rem 0.75rem !important;
        font-size: 16px !important;
    }

    .btn-mother {
        width: 290px !important;
    }
}

@media only screen and (max-width: 348px) {
    .btn-desc {
        display: none !important;
    }

    .btn-note {
        display: none !important;
    }

    .btn-setup {
        top: 200px;
    }
}

@media only screen and (max-height: 837px) {
    .btn-mother {
        top: 320px !important;
    }
}

@media only screen and (max-height: 675px) {
    .btn-mother {
        top: 210px !important;
    }
}

.fixe-nav {
    margin: 0px 0px 0px 0px;
}

.items-info {
    display: flex;
    align-self: end;
    align-items: center;
}

.items--price {
    margin-left: auto;
    padding: 5px 20px;
    background-color: #303032;
    border-radius: 20px;
}

.threebox {
    display: grid;
    grid-template-columns: 1fr repeat(12, minmax(auto, 92px)) 1fr;
    grid-gap: 40px;
    padding: 60px 0;
    padding: 0px 0px 90px 0px;
}

.threeboxs {
    grid-template-columns: 1fr repeat(12, minmax(auto, 60px)) 1fr;
}

.text--medium {
    font-family: 'open sans', sans-serif;
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
    color: #ecf0f1;
}

.threebox-items {
    grid-column: 2 / span 12;
    display: grid;
    grid-template-columns: repeat(16, minmax(auto, 60px));
    grid-gap: 40px;
}

.threebox-itemss {
    grid-template-columns: repeat(12, minmax(auto, 60px));
}


.threeboxss {
    display: grid;
}

.items-title {
    margin-bottom: 20px;
    position: relative;
    font-weight: bold;
}

.items-threebox:hover {
    transform: translateY(-7px);
}

.item-image-container {
    width: 100%;
    padding-top: 56.25%;
    overflow: hidden;
    position: relative;
    border-radius: 25px;
}

.item-image-container img {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.items-content {
    padding: 20px;
}

.metbot {
    position: relative !important;
    z-index: 3 !important;
}

.optela {
    margin-top: -80px !important;
    width: 100% !important;
}

.disfl {
    display: flex !important;
    justify-content: center !important;
}

.h1s {
    font-family: Poppins, sans-serif !important;
    line-height: 1.80857 !important;
    font-weight: 400 !important;
    font-size: 40px !important;
    margin-bottom: 3% !important;
}

.h1ss {
    font-size: x-large !important;
    width: 50% !important;
}

.allsa {
    display: flex !important;
    justify-content: center !important;
    margin: 1% !important;
}

.wis5 {
    width: 50% !important;
}

.topicsas {
    color: black !important;
    font-weight: bolder !important;
}

.wrapper-body {
    margin-top: 2%;
    background-color: #ffffff;
    width: 100%;
    height: auto;
    margin-bottom: 7%;
}

.video-wrapper {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.content-vids {
    display: grid;
    grid-gap: 25px;
    position: relative;
    text-align: center;
    color: white;
}

.h1-content {
    font-size: 3.5rem;
    margin-top: 10%;
    text-align: left;
    margin-left: 55px;
}

.content-butt-card {
    position: absolute;
    left: 55px;
}

.explore-avatar {
    background-color: #f670ff !important;
}

.p-content {
    font-size: 1.3rem;
    text-align: left;
    margin-left: 55px;
}

.video-wrapper2 {
    width: 100%;
    height: 100%;
}

.rowmargi {
    display: flex !important;
    justify-content: center !important;
    margin: 6% 0% 0% 0% !important;
}

.imga1 {
    top: 23% !important;
}

.imga2 {
    top: 18% !important;
}

.imga3 {
    top: 37% !important;
}

.imga4 {
    top: 10% !important;
}

.imga5 {
    top: 49% !important;
    height: 100% !important;
}

@media only screen and (max-width: 1000px) {
    .items-threebox {
        grid-column-end: span 6 !important;
    }
}

@media only screen and (max-width: 700px) {
    .threebox {
        grid-gap: 20px !important;
    }

    .items-threebox {
        grid-column-end: span 12 !important;
    }
}

@media only screen and (max-width: 500px) {
    .threebox {
        grid-template-columns: 10px repeat(6, 1fr) 10px !important;
        grid-gap: 10px !important;
    }

    .threebox-items {
        grid-column: 2 / span 6 !important;
        grid-template-columns: repeat(6, 1fr) !important;
        grid-gap: 20px !important;
    }

    .items-threebox {
        grid-column-end: span 6 !important;
    }
}

@media only screen and (max-width: 1356px) {
    .textili-1 {
        right: 80px;
    }

    .textili-2 {
        right: 65px;
    }

    .textili-3 {
        right: 78px;
    }

    .textili-4 {
        right: 5px;
    }
}

@media only screen and (max-width: 1000px) {
    .threebox-items {
        grid-template-columns: repeat(12, minmax(auto, 60px));
    }

    .textili-1 {
        right: 110px;
    }

    .textili-2 {
        right: 100px;
    }

    .textili-3 {
        right: 110px;
    }

    .textili-4 {
        right: 35px;
    }
}

@media only screen and (max-width: 344px) {
    .textili-1 {
        right: 80px;
    }

    .textili-2 {
        right: 65px;
    }

    .textili-3 {
        right: 78px;
    }

    .textili-4 {
        right: 5px;
    }
}


.homepage-component .react-multi-carousel-track {
    margin-bottom: 1%;
}

.container .btn1 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    color: white;
    font-size: 16px;
    border: none;
    cursor: pointer;

}

.container .btn:hover {
    /*background-color: rgb(213, 54, 165);*/
}

.desc-text {
    width: 400px;
    text-align: left;
    margin: 70px 0px 0px 0px;
}

.desc-text1 {
    width: 400px;
    text-align: left;
    margin: -202px 0px 0px 709px;
}

.btn-man {
    text-decoration: none !important;
    outline: none !important;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -ms-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
}

:focus {
    outline: 0;
}

.border-khat {
    border-top: 1px solid rgba(0, 0, 0, .1) !important;
    margin: -45px 0px 35px 0px;
}

.backi-wrappi {
    background: url('../../assets/images/backgroundss.jpg');
}

.backi-wrappii {
    background: url('../../assets/images/Untitled-1 copy.jpg');
}

.wrapper-vid .mid-vid {
    width: 100%;
    height: 100%;
}

.wrapper-vid .content-vid {
    width: 100%;
    height: 100%;
}

.p-waiti {
    text-shadow: 1px 1px 5px black;
}

.h1-waiti {
    text-shadow: 1px 1px 5px black;
}

.wrapper-vid .left-side,
.wrapper-vid .right-side {
    width: 100%;
    height: 50vh;
}

@media screen and (max-height: 800px) {
    .backi-wrappib {
        height: 460.5px;
    }

    .change-left {
        height: 100% !important;
    }
}

@media screen and (max-width: 1200px) {
    .backi-wrappib {
        height: 100vh;
    }

    .change-left {
        height: 50vh !important;
    }

    .h1-contents {
        margin-top: 1%;
    }
}

@media screen and (min-width: 1200px) {
    .wrapper-vid {
        display: flex;
    }

    .wrapper-vid .left-side,
    .wrapper-vid .right-side {
        width: 50%;
        height: 50vh;
    }
}


@media screen and (max-width: 348px) {
    .ready-bot {
        top: 0px;
    }
}

@media screen and (min-height: 800px) and (max-width: 1200px) {
    .backi-waiti {
        height: 70vh;
    }
}

@media screen and (max-width: 303px) {
    .backi-waiti {
        height: 790px;
    }
}


.bg-sliders {
    padding-top: 130px;
    z-index: 5;
    position: relative;
}

.container-style {
    height: 100%;
    position: relative;
}

.carousel-boullt {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 30px;
}

.carousel-boullt span {
    width: 45px;
    height: 12px;
    background-color: rgb(179, 178, 176);
    margin-left: 10px;
    border-radius: 10px;
    cursor: pointer;
}

.carousel-boullt .spani {
    background-color: white;
}

.ready-bot {
    position: relative !important;
}

.wrapper-body1 {
    margin-top: 5%;
}

@media screen and (max-width: 1200px) {
    .viddd {
        padding: 0px 10px 0px 10px;
    }
}

.react-multi-carousel-list {
    margin: 0px 0px 0px -13px;
}

.ai-purchase {
    grid-column-start: span 4;
    grid-column-end: 11 !important;
}

@media screen and (max-width: 1000px) {
    .ai-purchase {
        grid-column-start: span 6;
        grid-column-end: 10 !important;
    }
}

@media screen and (max-width: 700px) {
    .ai-purchase {
        grid-column-end: span 12 !important;
        grid-column-start: span 12;
    }
}

@media screen and (max-width: 500px) {
    .ai-purchase {
        grid-column-end: span 12 !important;
        grid-column-start: span 6;
    }
}