.bodyVerification {
    /* fallback for old browsers */
    background: #6a11cb;

    /* Chrome 10-25, Safari 5.1-6 */
    background: -webkit-linear-gradient(to right, rgba(106, 17, 203, 1), rgba(37, 117, 252, 1));

    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    background: linear-gradient(to right, rgba(106, 17, 203, 1), rgba(37, 117, 252, 1))
}

mes {
    width: auto !important;
}


#forgetCard {
    padding-top: 6.5rem !important;
    padding-bottom: 6.5rem !important;
}


.dsalis:hover {
    border-color: white !important;
    color: white !important;
}

.dsalis {
    color: white;
    width: 100% !important;
}

.messs {
    color: white !important;
}

.form-label {
    color: white !important;
}
.optsmes{
    border-radius: 1rem;
    max-width: 400px;
  }
  .change-input-sign{
    -webkit-text-fill-color: black !important;
    box-shadow: 0 0 0 30px gray inset !important;
    transition: all .2s linear;
    background: rgb(232, 240, 254) !important;
  }
  .change-input-sign:focus{
    box-shadow: none;
  }

