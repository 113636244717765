.blogmorseme .discover_me{
    height: 500px;
    background-color: #F5F5F5;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 30px;
    margin: 20px 0px 40px 0px;
}
.h2-blogss{
 padding-top: 20px !important ;
}

.blogmorseme .blogmorse{
    padding: 20px 0px 10px 20px !important ;
    text-align: left;

}
.blogmorseme .nameblog{
    position: relative;
    display: flex;
    /*padding: 25px 0px 0px 30px;*/
}
.blogmorseme .linkblog{
    position: relative;
    display: flex;
    padding: 0px 20px 0px 0px ;
    margin: -8px 0px 0px 0px;
    font-size: 1.4rem;
    color: #721D85;
    text-align: left;
}
.blogmorseme .descblog{
    position: relative;
    display: flex;
    text-align: left;
    /*padding: 10px 0px 0px 45px;*/
}
.blogmorseme .tagsblog{
    position: relative;
    padding: 10px;
    margin: 5px;
    border: 0px;
    border-radius: 30px;
    width: auto;
    text-align: center;
    background-color: rgb(190, 190, 190 , 33%);
}


.blogmorseme .links-mea{
    height:auto;
    width: fit-content;
    background-color: #F5F5F5;
    padding: 15px 0px 0px 15px;
    border-radius: 40px;
    margin-top: 6%;
    margin-bottom: 6%;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.blogmorseme .links-mea1{
    height: 225px;
    background-color: #F5F5F5;
    width: 430px;
    border-radius: 40px;
    margin-top: 6%;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.blogmorseme .links-mea2{
    height: 225px;
    background-color: #F5F5F5;
    width: 430px;
    border-radius: 40px;
    margin-top: 6%;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.blogmorseme .tagsblog2{
    position: relative;
    padding: 5px;
    margin: 20px 35px 5px 35px;
    border: 0px;
    border-radius: 30px;
    width: -webkit-fill-available;
    text-align: center;
    float: left;
    background-color: rgb(190, 190, 190 , 33%);
    font-size: 1.2rem;
}


.blogmorseme .flex-me{
    display: flex;
    gap: 0px 90px;
    flex-direction: row;
    margin-top: 0px;
}
.pages-blogs{
    justify-content: center;
    display: flex;
    margin-top: -70px;
    margin-left: 105px;
}
.pageblog{
    position: relative;
    padding: 5px;
    margin: 0px 0px 0px 30px;
    border: 0px;
    border-radius: 30px;
    width: 55px;
    text-align: center;
    float: left;
    background-color: rgb(190, 190, 190 , 33%);
}
.pageblog3{
    position: relative;
    padding: 5px;
    border: 0px;
    border-radius: 30px;
    width: 55px;
    text-align: center;
    float: left;
    background-color: rgb(190, 190, 190 , 33%);
}
.pageblog2{
    position: relative;
    padding: 6px;
    margin: 0px 0px 0px 30px;
    border: 0px;
    border-radius: 30px;
    width: 33px;
    text-align: center;
    float: left;
    background-color: rgb(190, 190, 190 , 33%);
}
.pageblog1{
    position: relative;
    padding: 6px;
    margin: 0px 0px 0px 20px;
    border: 0px;
    border-radius: 30px;
    width: 33px;
    text-align: center;
    float: left;
    background-color: rgb(190, 190, 190 , 33%);
}
.pageblog4{
    position: relative;
    padding: 6px;
    margin: 0px 0px 0px 20px;
    border: 0px;
    border-radius: 30px;
    width: 33px;
    text-align: center;
    float: left;
    background-color: rgb(190, 190, 190 , 33%);
}
@media only screen and (max-width:1400px){

    /*.blogmorseme .discover_me {*/
    /*    height: 500px;*/
    /*    margin-bottom: 20px;*/
    /*    width: 26%;*/
    /*}*/
}

@media only screen and (max-width:1265px){

    /*.blogmorseme .discover_me {*/
    /*    height: 650px;*/
    /*    margin: 137px 0px 0px 0px;*/
    /*    width: 26%;*/
    /*}*/

}



@media only screen and (max-width:1054px){
    /*.blogmorseme .discover_me{*/
    /*    margin: 106px 0px 0px 0px;*/
    /*}*/

}
@media only screen and (max-width:991px){

    /*.blogmorseme .discover_me {*/
    /*    display: none;*/
    /*}*/

}

