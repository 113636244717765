.main-swiper {

    z-index: 1;
    border-radius: 70px !important;
    border: 1px !important;

}

.main-swiper .swiper-slide {
    transition: transform 0.3s ease-in-out;
    transform: scale(1);
    border-radius: 70px !important;
    border: 1px !important;

}

.main-swiper .swiper-slide-active {
    transform: scale(3);
}

.swiper-container {
    text-align: center;
    border-radius: 70px !important;
    border: 1px !important;

}

.swiper-slide {
    font-size: 25px;
    height: 501px;
    border-radius: 70px !important;
    border: 1px !important;
    display: flex;


}

.vedio_banner {
    height: 100%;
    overflow: hidden;
    width: 100%;
    object-fit: fill;
}

.video_contaier {
    width: 100%;
}

.video_contaier_1 {
    width: 100%; /* Adjust based on your design needs */
    height: 500px;
    overflow: hidden; /* Ensures the border-radius clips the content */
    border: 1px #000; /* Black border, adjust color as needed */
    border-radius: 70px; /* Rounded corners */
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0);
}

/*.container-home_page_slider{*/
/*    justify-content: space-around;*/
/*    display: flex;*/
/*}*/
.home_page_slider {
    flex: 1 1 auto;
    text-align: center;
    border-radius: 40px;
    font-family: "Sofia Pro",sans-serif;
    border: none;
    cursor: pointer;
    text-transform: uppercase;
    outline: none;
    overflow: hidden;
    position: relative;
    color: black;
    font-weight: 700;
    font-size: 14px;
    background: #78c7d2;
    padding:5px 20px;
    margin:  10px;
    width:90%;
    /*box-shadow: 0 0.313rem 0.938rem #0003;*/
    z-index: 3;
}
.home_page_slider:hover{
    background: #0c5460;
    color: white;
}
.home_page_slider::after{

    left: 0;
    top: 0;
    height: 490%;
    width: 140%;
    background-color: white;

    -webkit-transition: all .5s ease-in-out;
    transition: all .5s ease-in-out;
    -webkit-transform: translateX(-98%) translateY(-25%) rotate(45deg);
    transform: translateX(-98%) translateY(-25%) rotate(45deg);

}
.home_page_slider:hover::after{
    -webkit-transform: translateX(-9%) translateY(-25%) rotate(45deg);
    transform: translateX(-9%) translateY(-25%) rotate(45deg);
}

