.roadmap-component{
    background-image: url(../../assets/images/rmb.jpg);
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
}
.titles{
    color: #fff;
    font-size: 22px;
    font-weight: 700;
    letter-spacing: 2px;
    text-transform: uppercase;
}
.vertical-timeline-element>div {
    border-radius: 20px;
}