.img-banner1 {
    position: relative;
    height: 50vh;
    /*width: 100vw;*/
    overflow: initial;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 15px;
}

.showaitformbtn {
    margin-top: 15px;
    padding: 10px;
    margin: 10px;
    border-radius: 40px;
    background-color: #721d85;
    width: 300px;
    margin-top: 3%;
    margin-bottom: 2%;
    box-shadow: 0 0px 9px 2px #de53a9 !important;
}

.customize-me {
    text-align: justify;
    padding: 2px 10px;
}

.waitlistInfo {
    padding: 10px;
}
.waitlistInfo1 {
    margin-top: 90px;
}
.form_promolabel{
    margin-top: 10px;
}
.button-promo{
    margin-top: 40px;
}
.buttom-promos{
    display: flex;
}
@media screen and (max-width:348px){
    .showaitformbtn{
        width: 100%;
        margin-left: 2px;
    }
}
@media screen and (max-width:775px){
    .custs{margin-bottom: 8%;}
}

@media screen and (max-width: 990px) {
    .waitlistTitle {
        color: #721d85;
        padding-top: 5px;
    }
    .waitlistTitle1 {
        color: #721d85;
        padding-top: 5px;

    }
}

@media screen and (min-width: 990px) {
    .waitlistInfo {
        padding: 10px;
    }
}

.wait_list_info_row {
    display: flex;
}

@media screen and (max-width: 775px) {
    .wait_list_info_row {
        display: inline-block !important;
    }
}

#waitlistinfocard {
    padding: 10px 10px;

    margin: 5px;

}

.wait_list_info {
    text-align: start;
    justify-content: start;
}
.img-bng1{
    z-index: 0;
    position: absolute;
    width: 100%;
    height: 550px;
    min-width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    margin: 42px 0px 0px 0px;
}
@media screen and (max-width:1150px){
    .img-bng1{
        height: 100%;
    }
}
.img-banner2{
    position: relative;
    height: 50vh;
    /* width: 100vw; */
    overflow: initial;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 15px;
}
.waitlistTitle1 {
    color: #721d85;
    padding-top: 25px;
    margin-bottom: 0px;
    height: 90px;
    border-bottom: 1px solid #9b73ff;
    box-shadow: 0px -20px 20px 20px #9b73ff;
    background: white;
    border-radius: 5px;
}

.waitlistTitle {
    color: white;
    padding-top: 25px;
    position: absolute;
    top: 360px;
    left: 100px;
    font-size: 45px;
    text-shadow: #252525 1px 1px 5px;
}
.waitinfotitle{
    margin-bottom: 2%;
    margin-top: 4%;
    color: white;
}
.borderme{
    border:1px solid;
    margin-bottom: 4.2%;
    position: relative;
    display: inline-block;
    text-align: center;
    width: 540px;
    color: #ff4bba;
}

@media screen and (min-width:1150px){
    .waitlistTitle{
        top: 395px;
    }
}
.waitlistform{
    background-color: #a1bce5;
}
.waitlistInfo .container {
    display: flow-root;
}
.form-check-label {
    color: black;
}
.deffines{
    height: 1px;
}

.form-labelss.form-label{
    color: black !important;
}
.form-control{
    border: 2px solid #9b73ff;
    color: #8200c7;


}


.form-control::placeholder{
    color: #8200c7;
}
.form-control:disabled{
    border: 2px solid #9b73ff;
}
.input-group{
    margin-bottom: 10px;
}
.input-group-text{
    border: 1px solid #8200c7;
    color: #8200c7;
    background-color: #eeeeee;

}
.form-select{
    border: 1px solid #8200c7;
    color: #8200c7;
}
.waitinglistform {
    margin-bottom: 25px;
    padding: 25px;
    border-radius: 5px;
    text-align: start;
    justify-content: start;
    box-shadow: 0px -20px 20px 20px #9b73ff;
    /* fallback for old browsers */
    background-color: white !important;
    /* Chrome 10-25, Safari 5.1-6 */
    /*background: -webkit-linear-gradient(to right, rgba(106, 17, 203, 1), rgba(37, 117, 252, 1));*/

    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    /*background: linear-gradient(to right, rgba(106, 17, 203, 1), rgba(37, 117, 252, 1))*/

}
.waitinglist{
    height: 100%;
    min-height: 100vh;
    position: relative;
    background-image: url(../../assets/images/rmb.jpg);
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
}
.form-item-socialMedia-charactercount {
    text-align: end;
    bottom: 25px;
    right: 25px;
    position: relative;
}

.tableme {
    position: relative;
    outline: 0;
    margin: auto;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    padding: 24px;
    background-color: #444446;
    border-radius: 10px;
    width: 60%;
    margin-top: 80px;
    margin-bottom: 80px;
    height: 552px;
    padding-top: 60px;
    flex-direction: column;
}

.h2-table-header {
    top: -30px;
    position: relative;
    color: white;
}

.aas {
    color: gray;
    margin: 6%;
    font-size: 20px;
}

.prof_img {
    width: 210px;
    border-radius: 25px;
    height: 125px;
}

.table_pic_width {
    width: 40%;
}

.pfixes {
    margin: 0;
    width: 100%;
}

@media screen and (max-width: 1320px) {
    .tableme {
        width: 95%;
    }
}
@media screen and (max-width: 710px) {
    .waitlistTitle{
        font-size: 32px;
        left: 20px;
        top: 380px;
    }
}
@media screen and (max-width: 350px) {
    .waitlistTitle{
        left: 0px;
    }
}
@media screen and (max-width: 600px){
    .borderme{
        width: 80%;
    }
}
@media screen and (max-width: 480px){
    .borderme{
        width: 90%;
    }
}
@media screen and (max-width: 400px){
    .borderme{
        width: 100%;
    }
}
@media screen and (max-width: 580px){
    .waitinfotitle{
        margin-top: 15%;
    }
}
@media screen and (max-width: 325px){
    .waitinfotitle{
        margin-top: 30%;
    }
}
.td-soc {
    display: inline;
    margin-left: -10px;
    padding: 14px !important;
    display: contents;
}

.muicustom {
    text-align: left !important;
    padding: 13.5px !important;
}

.muicustom1 {
    padding: 0px 16px 0px 0px !important;
    text-align: left !important;
}

.h2-custommi {
    margin-bottom: 3%;
    font-size: 1.5rem;
    margin-top: -2%;
}
.custs {
    background: rgb(222, 83, 169) !important;
    box-shadow: 0px 0px 10px 10px !important;
    color: beige !important;
    border-radius: 1.5rem !important;
    height: 620px;
    display: inline-block;
}
.waitform-label.form-label{
    color: black !important;
    padding: 10px 0px;
}
.colorimei{
    color: black !important;
}
@media screen and (max-width:1199px){
    .custs{height: 660px;}
}
@media screen and (max-width:991px){
    .custs{height: 640px;}
}
@media screen and (max-width:775px){
    .custs{height: 620px;}
}
.customize-mes1{
    overflow: hidden !important;
}
@media screen and (max-width:1399px){
    .customize-me{overflow-y: scroll !important;}
}
@media screen and (max-width:775px){
    .customize-me{overflow-y: hidden !important;}
}
@media screen and (max-width:442px){
    .customize-me{overflow-y: scroll !important;}
}
.h111{
    margin-top: 6%;
    color: white !important;
}
.defaultme{
    font-size: 25px;
    display: inline-flex;
    top: 3px;
    position: relative;
}
.pdefaultme{
    display: inline-flex;
    padding: 0px 0px 0px 20px;
}
/*
<a className="aas" href={historyRow.discord}>
                        <FontAwesomeIcon icon={faDiscord}/>
                        </a>
                        <a className="aas"href={historyRow.instagram}>
                        <FontAwesomeIcon icon={faInstagram}/>
                        </a>
                        <a className="aas" href={historyRow.linkedin}>
                        <FontAwesomeIcon icon={faLinkedin}/>
                        </a>
                        <a className="aas"href={historyRow.twitter}>
                        <FontAwesomeIcon icon={faTwitter}/>
                        </a>
                        */