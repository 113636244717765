
.dashboard-container {
    background-color: #242424;
    font-family: 'Poppins', sans-serif !important;
}

.sidebar {
    background-color: #383838;
    font-size: 19px;
    color: white !important;
    line-height: 20px !important;
    font-weight: 500 !important;
    font-family: 'Poppins', sans-serif !important;
    padding: 0px;

}

.dashboard-container {
    height: 100%;
    display: flex;
    flex-direction: column;
}


/* Ensures both sidebar and content columns grow equally */


.sidebar .list-group-item {
    background-color: #383838;
    border: none;
    color: #c7c7c7;
    padding-top: 25px;
    padding-bottom: 25px;
}

.sidebar .list-group-item.active {
    background-color: #6A6A6A;
    color: white;
}

.sidebar .list-group-item:last-child {
    color: #0a47a9;
}

.main-content {
    background-color: #242424;
    padding: 2rem;
    text-align: left;

}

.main-content .badge {
    padding: 10px;
    margin: 5px;
    margin-bottom: 10PX;
    background-color: #4456B4 !important;
    font-size: 20px;
    font-style: normal;

}

.main-content h2 {
    font-size: 40px;
    color: white !important;
    margin-bottom: 1rem;
}

.dashboard-container {
    background-color: #2F2F2F;

    min-height: 506px;
}

.progress-section .progress-item {
    margin-bottom: 1.5rem;
}

.progress-item .title {
    font-size: 1rem;
    color: #3c3d41;
    margin-bottom: 0.25rem;
}

.progress-item .date {
    font-size: 0.875rem;
    color: #6c757d;
    margin-bottom: 0.5rem;
}

.progress-bar {
    background-color: #28a745;
    height: 1rem;
}

/* Switch styling */

#enable-chatting .custom-control-label::after {
    top: .8rem;
    width: 1.75rem;
    height: 0.9rem;
}

/* Since Bootstrap is used, there might be no need for extra CSS unless you need to override default Bootstrap styles. */

/* For example, if you want to increase the spacing between toggle buttons */
.btn-group-toggle > .btn, .btn-group-toggle > .btn-group > .btn {
    margin-bottom: 10px; /* Adjust the bottom margin */
}

/* Custom styles for your form */
.form-group {
    margin-bottom: 20px;
}

.ai_link {
    padding: 10px;
}

.ai_link .form-control {
    border: 0px;
    border-radius: 20px;
    text-align: center;
    color: blue;
}

.ai_link .form-control::placeholder {
    color: blue;
}

.sensibility {
    color: white;
    text-align: start;
    padding: 15px
}

.tlb_ai_order {
    color: white;
    padding: 10px;
    margin: 10px;
}

.progress-bar-txt {
    color: white;
    margin: 20px;
}

.developer_information {
    padding: 30px;
    color: white;

}

.pagination-custom .page-link {
    background-color: gray;
    color: white;
}

.pagination-custom .page-item.active .page-link {
    background-color: white;
    border-color: darkgray;
    color: black;
}
.pagination-custom .page-item.disabled .page-link {
    background-color: darkgray;
    border-color: darkgray;
}

textarea.ai_link.form-control {
    border: 0px;
    border-radius: 40px;
    text-align: center;
    color: blue;
}

textarea.ai_link.form-control::placeholder {
    color: blue;
}

input.ai_link.form-control {
    border: 0px;
    border-radius: 40px;
    text-align: center;
    color: blue;
}

input.ai_link.form-control::placeholder {
    color: blue;
}

.developer_form_adjustment {
    justify-content: center;
    align-items: flex-end;
    padding: 10px;
}

.developer_form_margin {
    margin-bottom: 10px;
    padding: 10px;
}

.sm-8.align-items-center.links {
    padding: 10px;
}

.link-columns-wrapper {
    display: flex;
    flex-direction: row;
}

.link-input-fields-wrapper {
    display: block;
}

.developer_form_margin .row .form-label {
    padding: 0px 0px 30px 0px;
}

/* Other responsive and aesthetic styles as needed */
.developer_das_information_upload_btn {
    border-radius: 50px;
    background: white;
    padding-top: 10px;
    padding-bottom: 5px;
}

.developer_das_information_upload_btn p {
    color: black;
}

.bugReport {
    padding: 25px;

}

.bugReport_Check_point {
    padding: 25px;
    color: white;
}

.bugReport_Check_point .form-check-label {
    color: white;
}

textarea.bugReport.form-control {
    border: 0px;
    border-radius: 40px;

    color: blue;
}

textarea.bugReport.form-control::placeholder {
    color: blue;
}

input.bugReport.form-control {
    border: 0px;
    border-radius: 40px;
    text-align: center;
    color: blue;
}

input.bugReport.form-control::placeholder {
    color: blue;
}

.conversion {
    color: white;
    padding: 10px;
    margin: 10px;
    border-radius: 5px;
}

.conversion_title {
    font-size: 18px;
    font-weight: bold;
    padding-top: 10px;
}

.conversion_body {
    text-align: start;
    padding-top: 10px;
}
.conversation-entry{
    background: black;
    margin-top: 10px;
    padding: 15px;
    border-radius: 30px;
}
.user-conversations{
    padding-top: 10px;
}
.conversation_summary{
    padding-top: 30px;
    border-radius: 30px;
    background: #0a47a9;
}
.empty_space_conversation{
    height: 100px;
}
@media (max-width: 576px) { /* Small devices */
    .empty_space_conversation {
        height: 10px; /* Slightly larger space on small devices */
    }
}
@media (max-width: 768px) { /* Medium devices */
    .row-height-custom {
        min-height: 30px !important; /* Increase minimum height for medium devices and up */
    }
}
