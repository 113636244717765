* {
    font-family: 'montserrat', sans-serif;
  }
  
  body {
    margin: 0;
    padding: 0;
  }
  .row-opt{
    display: flex;
    justify-content: center;
    margin-top: 10%;
  }
  .nonoss{
    display: flex;
    justify-content: center;
  }
  .row-optt{
    display: flex;
    justify-content: center;
    margin-top: 8%;
    margin-bottom: 10%;
  }
  .colmes{
    margin-top: 5%;
  }
  .customes{
    background-size: contain !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    height: 450px !important;
    width: 450px !important;
    margin-top: 5% !important;
    border-radius: 27px !important;
    display: flex !important;
    justify-content: center !important;
    background-image: url('./coming_soon_marketplace.jpg') !important;
  }
  .page {
    background: #f1f1f1;
    display: flex;
    flex-wrap: wrap;
  }
  /* .row{
    display: flex;
    flex-wrap: wrap;
  }
  .col {
    flex: 1;
    height: 70vh;
    position: relative;
  } */
  
  .countdown-col {
    background: url(coming_soon_marketplace.jpg);
    background-repeat: no-repeat;
    width: 650px;
    height: 650px;
    background-size: cover;
    
  }
  
  .time {
    color: rgb(0, 0, 0);
    text-transform: uppercase;
    width: 90%;
    display: flex;
    justify-content: center;
    filter: blur(0);
    animation-name: example;
    animation-duration: 2s;
    animation-iteration-count:infinite
  }
  @keyframes example {
    from {color: rgb(0, 0, 0);}
    to {color: rgb(222,83,169);}
  }
  .buttom {
    position: relative;
    /* top: 60%;
    left: 50%;
    transform: translate(-50%, -50%); */
    text-align: center;
    font-weight: bolder;
  }
  
  .middle {
      position: relative;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
    }
  .time span {
    padding: 0 14px;
    font-size: 10px;
  }
  
  .time span div {
    font-size: 40px;
  }
  
  .newslatter {
    width: 90%;
  }
  
  .newslatter h4 {
    font-style: italic;
    font-size: 12px;
  }
  
  .newslatter input, .newslatter button {
    display: block;
    margin: 12px auto;
    width: 100%;
    max-width: 400px;
    box-sizing: border-box;
    padding: 14px 20px;
    border-radius: 30px;
    border: 1px solid #ddd;
    outline: none;
  }
  
  .newslatter-button {
    background: linear-gradient(125deg, #3498db, #34495e);
    color: #fff;
    cursor: pointer;
    transition: 0.4s;
  }
  
  .newslatter-button:hover {
    opacity: 0.7;
  }
  
  
  @media screen and (max-width: 900px) {
    .col {
      flex: 100%;
    }
  }
  