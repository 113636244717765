.user-info {
    text-align: left;
}
.user-social{
    text-align: left;

}

.user-profile-page {

    width: 100%;
    height: 100%;
    min-height: 100vh;
    margin-top: -80px;
    position: relative;
}
.img-banner{
    position: relative;
    height: 50vh;
    width: 100vw;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-top: 3px;
}
.img-bng{
    z-index: 0;
    position: absolute;
    width: 100%;
    height: auto;
    min-width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    margin: 42px 0px 0px 0px;
}
@media screen and (max-width:1150px){
  .img-bng{
    height: 100%;
    width: auto;
    min-width: auto;
}
}
@media screen and (max-width:495px){
  .img-bng{
    margin-left: -88px;
}
}
.section-cotainer{
    padding: 0 92px;
}
.user-profile {
    position: relative;
    justify-items: end;
    padding-top: 16px;
}
.user-profile-container {
    width: 128px;
    height: 128px;
    float: left;
    top: -64px;

    /* Chrome 10-25, Safari 5.1-6 */
    background: #6a11cb;

    /* Chrome 10-25, Safari 5.1-6 */
    background: -webkit-linear-gradient(to right, rgba(106, 17, 203, 1), rgba(37, 117, 252, 1));

    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    background: linear-gradient(to right, rgba(106, 17, 203, 1), rgba(37, 117, 252, 1));

    position: absolute;
    border-radius: 50%;
    left: 50px;
}
.edit-user {
    width: 180px;
    height: 48px;
    float: right;
    right: 100px;
    position: absolute;
}
.button-edit {
    padding: 15px 100px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    background-color: white;
    color: black;
    border: 2px solid #e7e7e7;
    border-radius: 50px;
    transition: all .3s var(--emo-in-out);
    --emo-in-out: cubic-bezier(0.4,0,0.2,1);
}
.text-edit {
    display: inline-flex;
    align-items: center;
    flex-direction: column;
    font-weight: 600;
    text-align: center;
    white-space: nowrap;
    cursor: pointer;
}
.button-edit:hover {
    border-color: transparent;
    box-shadow: 0 16px 48px 0 rgba(0,0,0,.24);
    transform: scale(1.1);
    text-decoration: none;
    opacity: 1;
}
.user-info{
    display: grid;
    grid-auto-flow: row;
    padding: 50px;
}
.username{
    font-weight: 900;
    letter-spacing: .01em;
    float: left;
    position: absolute;
    margin-top: 78px;
    left: 50px;
}
.user-id{
    font-size: 1.125rem;
    font-family: ff-real-text-pro,sans-serif;
    font-weight: 500;
    letter-spacing: normal;
    opacity: .5;
    text-shadow: 0 8px 16px rgba(0,0,0,.2);
    position: absolute !important;
    float: left;
    margin-top: 127px;
    left: 50px;
}
.user-dynamic{
    display: flex;
    gap: 16px;
    padding: 8px 0;
}
.user-profile-dynamic{
    font-size: 1rem;
    font-family: ff-real-headline-pro,sans-serif;
    font-weight: 500;
    letter-spacing: normal;
    text-transform: none;
    margin: 0;
    cursor: pointer;
    transition: opacity .15s ease;
    font-family: inherit;
    padding: 0;
    margin: 0;
    border: 0;
    background: none;
    -webkit-tap-highlight-color: transparent;
    color: #000;
    margin-top: 144px;
    left: 52px;
    position: relative;
}
.user-profile-dynamic:hover {
    opacity: 0.7;
    text-decoration: none;
}
.user-profile-editor-left ,
.user-profile-editor-right {
    width: 100%;
    height: 50%;
    font-size: 1rem;
    color: #888;
    min-width: min(400px,40vw);
}
.user-profile-editor-body .user-profile-editor-info{
    width: 100%;
}
@media screen and (min-width:1200px){
    .user-profile-editor-body{
      display: flex;
    }
    .user-profile-editor-body .user-profile-editor-left ,
    .user-profile-editor-body .user-profile-editor-right {
      width: 50%;
      height: 50%;
    }
}
.user-profile-editor-body{
    gap: 32px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.editpops .modal-dialog {
    width: 985px !important;
    height: 795px;
    max-width: 985px !important;

}

/*.editpops .modal-content {*/
/*    background-color: #4f4f4f;*/
/*}*/
@media screen and (max-width:987px){
    .editpops .modal-dialog {
        width: 100% !important;
}
}
.user-profile-editor-info{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    padding: 12px;
    gap: 4px;
    color: #000;
    flex-grow: 1;
    border-color: #aaa;
    border-style: solid;
    border-width: 1px 2px;
    transition: border-color .15s ease,background-color .15s ease;
    border-radius: 8px 8px 0 0;
    border-top-width: 2px;
    box-sizing: border-box;
    height: 437px;
}
.form-item-container{
    display: flex;
    align-items: center;
    font-weight: 600;
    padding-left: 6px;
    text-align: start;
    transition: color .15s ease;
    box-sizing: border-box;
    border-style: solid;
    border-color: #aaa;
    height: 55px;
    border-radius: 8px;
    position: relative;
}
.input-form{
    width: 100%;
    padding-left: 6px;
    flex-grow: 1;
    font-weight: 500;
    font-size: 1rem;
    text-align: left;
    font-family: inherit;
    color: inherit;
    border: 0;
    background: none;
    outline: none;
    padding: 0;
    margin: 0;
    position: absolute;
    top: 23px;
}
.from-info div{
    top: 0px;
    position: absolute;
}
.form-item_aboutContainer{
    height: 100%;
    overflow-y: hidden;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
}
.form-item-about{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    padding: 12px;
    gap: 4px;
    color: #000;
    flex-grow: 1;
    border-color: #aaa;
    border-style: solid;
    border-width: 2.5px 2.5px;
    transition: border-color .15s ease,background-color .15s ease;
    border-radius: 8px;
    box-sizing: border-box;
}
.form-item-about-header{
    display: flex;
    align-items: center;
    font-weight: 600;
    padding-left: 6px;
    text-align: start;
    transition: color .15s ease;
    position: relative;


}
.about-head {
    top: 0px;
    position: absolute;
}
.form-item-input{
    width: 100%;
    padding-left: 6px;
    flex-grow: 1;
    font-weight: 500;
    font-size: 1rem;
    text-align: left;
    font-family: inherit;
    color: inherit;
    border: 0;
    background: none;
    outline: none;
    text-align: start;
    resize: none;
    top: 23px;
    position: relative;
    left: -7px;
}
.form-item-aboutcharactercount{
    text-align: end;
    bottom: -80px;
    right: 0px;
    position: absolute;
}
.butonsss{
    background-color: #000;
    color: #fff;
    padding: 15px 56px;
}
.footi{
    justify-content: center !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 2;
    box-shadow: 0 -8px 8px 2px #fff;;
}
.buttonssse{
    padding: 15px 56px;
    color: white;
    border: 0;
    background-color: red;
    font-weight: 600;
    transition: all .3s var(--emo-in-out);
    --emo-in-out: cubic-bezier(0.4,0,0.2,1);
}
.buttonssse:hover{
    border-color: transparent;
    transform: scale(1.1);
    text-decoration: none;
    opacity: 1;
}
.ssss{
    position: fixed;
    background-color: #fff;
    top: 5px;
    right: calc(25% - 243px);
    border: 0px;
}
.user-profile-page {
  background-image: url("../../assets/img/184101.jpg") !important;
  background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
}
@media screen and (max-width:1030px){
    .ssss {
      right: calc(25% - 239px) !important;
    }
  }
  @media screen and (max-width:965px){
    .ssss {
      right: calc(25% - 232px) !important;
    }
  }
  @media screen and (max-width:932px){
    .ssss {
      right: calc(27% - 225px) !important;
    }
  }
  @media screen and (max-width:840px){
    .ssss {
      right: calc(27% - 215px) !important;
    }
  }
  @media screen and (max-width:802px){
    .ssss {
      right: calc(29% - 205px) !important;
    }
  }
  @media screen and (max-width:712px){
    .ssss {
      right: calc(31% - 195px) !important;
    }
  }
  @media screen and (max-width:683px){
    .img-bng{
        height: 100% !important;
        width: auto !important;
    }
  }
  @media screen and (max-width:638px){
    .user-profile-editor-body{
        flex-direction: column;
      }
    .ssss {
      right: calc(33% - 185px) !important;
    }
  }
  @media screen and (max-width:570px){
    .ssss {
      right: calc(33% - 175px) !important;
    }
  }
  @media screen and (max-width:538px){
    .ssss {
      right: calc(33% - 165px) !important;
    }
  }
  @media screen and (max-width:525px){
    .ssss {
      right: calc(33% - 155px) !important;
    }
  }
  @media screen and (max-width:494px){
    .ssss {
      right: calc(33% - 145px) !important;
    }
  }
  @media screen and (max-width:464px){
    .ssss {
      right: calc(33% - 135px) !important;
    }
  }
  @media screen and (max-width:433px){
    .ssss {
      right: calc(33% - 125px) !important;
    }
  }
  @media screen and (max-width:404px){
    .ssss {
      right: calc(33% - 115px) !important;
    }
  }
  @media screen and (max-width:373px){
    .ssss {
      right: calc(33% - 105px) !important;
    }
  }
  @media screen and (max-width:346px){
    .ssss {
      right: calc(33% - 95px) !important;
    }
  }
  @media screen and (max-width:308px){
    .ssss {
      right: calc(33% - 85px) !important;
    }
  }
  @media screen and (max-width:279px){
    .ssss {
      right: calc(33% - 75px) !important;
    }
  }
  @media screen and (max-width:505px){
    .user-profile-container {
        top:-145px !important;
    }
  }
  @media screen and (max-width:340px){
    .edit-user{
        display: contents;
    }
    .user-id{
        margin-top: 70px !important;
    }
    .username{
        margin-top:24px !important;
    }
    .user-profile-dynamic{
        margin-top: 95px !important;
    }
  }
  @media screen and (max-width:325px){
    .user-id{
        left: 10px !important;
    }
    .username{
        left: 10px !important;
    }
    .user-profile-dynamic{
        left: 10px !important;
    }
  }