.navbar-component{
  height: 100%;
}
.sticky-nav {
    position: sticky !important;
    top: 0 !important;
  }
  .nav-items{
    padding: 0 15px  !important;
    color: #fff  !important;
    font-size: 16px  !important;
    line-height: 20px  !important;
    font-weight: 600  !important;
    font-family: 'Poppins', sans-serif !important;
  }
#userdropbox{
  padding: 0 15px  !important;
  color: #fff  !important;
  line-height: 20px  !important;
  font-weight: 500  !important;
  font-family: 'Poppins', sans-serif !important;
  font-size: larger;
  text-align: start;
  width: 200px !important;
}
#specialAI{
  padding: 0px  !important;
  color: #fff  !important;
  line-height: 20px  !important;
  font-weight: 500  !important;
  font-family: 'Poppins', sans-serif !important;
  font-size: larger;
  text-align: start;

}

.navbar-nav{
  align-items: center;
}
button.book_demo {
  border-radius: 40px;
  font-family: Sofia Pro,sans-serif;
  border: none;
  text-align: center;
  cursor: pointer;
  text-transform: uppercase;
  outline: none;
  overflow: hidden;
  position: relative;
  color: #000;
  font-weight: 700;
  font-size: 16px;
  background: #78c7d2;
  padding: 5px 10px;
  margin: 10px;
  /*box-shadow: 0 0.313rem 0.938rem #0003;*/
  z-index: 3;
}
button.book_demo:hover{
  background: #0c5460;
  color: white;
}
button.book_demo::after{
  left: 0;
  top: 0;
  height: 490%;
  width: 140%;
  background-color: white;
  -webkit-transition: all .5s ease-in-out;
  transition: all .5s ease-in-out;
  -webkit-transform: translateX(-98%) translateY(-25%) rotate(45deg);
  transform: translateX(-98%) translateY(-25%) rotate(45deg);
}
button.book_demo:hover::after{
  -webkit-transform: translateX(-9%) translateY(-25%) rotate(45deg);
  transform: translateX(-9%) translateY(-25%) rotate(45deg);
}

#nevItem{
  font-size: larger;
  color: black;
}
  .nav-login{
    background-color: #252527  !important;
    border: 0px;
    line-height: 1rem  !important;
    font-weight: 600  !important;
    letter-spacing: .063rem  !important;
    text-decoration: none  !important;
    text-transform: none  !important;
    font-family: 'Poppins', sans-serif !important;
    display: block  !important;
    font-size: 18px  !important;
  }
.navbar-toggler-icon{
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 30 30%27%3e%3cpath stroke=%27rgba%28255, 255, 255, 0.55%29%27 stroke-linecap=%27round%27 stroke-miterlimit=%2710%27 stroke-width=%272%27 d=%27M4 7h22M4 15h22M4 23h22%27/%3e%3c/svg%3e") !important ;
  }
  .nav-items:hover {
    color: rgb(98, 77, 182)  !important;
    transform: scale(1.1) !important;
  }
  .nav-login:hover{
    color: rgb(98, 77, 182)  !important;
    transform: scale(1.1) !important;
  }
  .nav-back{
    background-color: #252527 !important;
  }
  @media only screen and (max-width: 992px){
    .nav-login{
      padding: 0 15px  !important;
      color: #fff  !important;
      font-size: 19px  !important;
      line-height: 20px  !important;
      font-weight: 500  !important;
      /*background-color: transparent  !important;*/
      font-family: 'Poppins', sans-serif !important;
      margin:0  !important;


    }
    .nav-login:hover{
      color: rgb(98, 77, 182)  !important;
      transform: scale(1) !important;
    }
    .navbar-expand-lg .navbar-nav .dropdown-menu.show{
      width: 200px !important;

    }
  }
  .logo{
    width: 160px;
    margin: -4px 0px -4px 0px;
  }
  .nav-st{
    font-size: larger;
    color: white;
  }
  .nav-stt{
    font-size: larger;
    color: white !important;
  }
  @media only screen and (max-width: 990px){
    .logo{
      width: 200px  !important;
      margin: -7px 0px  !important;
    }

  }
  @media only screen and (max-width: 575px){
    .logo{
      width: 200px  !important;
      margin: 0px 0px  !important;
    }

  }

