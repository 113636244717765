@import url('https://fonts.googleapis.com/css?family=Rajdhani:300,400,500,600,700');
@import url('https://fonts.googleapis.com/css?family=Poppins:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i');
@import url('https://fonts.googleapis.com/css?family=Raleway:100,400,600,700,900&display=swap');
@import url('https://fonts.googleapis.com/css?family=Dosis:400,500,600,700,800&display=swap');

.footer-component * {
    box-sizing: border-box !important;
}

.footer {
    background-color: #252527;
}

.footer-component html {
    scroll-behavior: smooth !important;
}

.footer-component body {
    color: #666666 !important;
    font-size: 14px !important;
    font-family: 'Poppins', sans-serif !important;
    line-height: 1.80857 !important;
    font-weight: normal !important;
}

.footer-component a {
    text-decoration: none !important;
    outline: none !important;
    -webkit-transition: all .3s ease-in-out !important;
    -moz-transition: all .3s ease-in-out !important;
    -ms-transition: all .3s ease-in-out !important;
    -o-transition: all .3s ease-in-out !important;
    transition: all .3s ease-in-out !important;
}

.blas {
    color: black !important;
}

.yoyoss {
    position: relative !important;
    left: 47px !important;
    color: black !important;
}

.discords {
    position: relative !important;
    left: 47px !important;
    color: black !important;
    top: 5px !important;
    right: -1px !important;
}

.headmes {
    margin-top: 10px;
    font-size: 30px !important;
}

.foot-links {
    font-size: 21px !important;
    color: white !important;
}

.footer-component .footer-component h1,
h2,
h3,
h4,
h5,
h6 {
    letter-spacing: 0 !important;
    font-weight: normal !important;
    position: relative !important;
    padding: 0 0 10px 0 !important;
    line-height: normal !important;
    color: #111111 !important;
    margin: 0
}

.footer-component .footer-component h1 {
    font-size: 24px !important;
}

.footer-component h2 {
    font-size: 22px !important;
}

.footer-component h3 {
    font-size: 18px !important;
}

.footer-component h4 {
    font-size: 16px !important;
}

.footer-component h5 {
    font-size: 14px !important;
}

.footer-component h6 {
    font-size: 13px !important;
}

.footer-component *,
*::after,
*::before {
    -webkit-box-sizing: border-box !important;
    -moz-box-sizing: border-box !important;
    box-sizing: border-box !important;
}

.footer-component .footer-component h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
    text-decoration: none !important;
    opacity: 1
}

.footer-component button:focus {
    outline: none !important;
}

.footer-component ul,
li,
ol {
    margin: 0 !important;
    padding: 0 !important;
    list-style: none !important;
}

.footer-component .footer-component p {
    margin: 0 !important;
    font-weight: 500 !important;
    font-size: 15px !important;
    line-height: 24px !important;
}

.footer-component a {
    text-decoration: none !important;
    outline: none !important;
}

.footer-component a,
.btn {
    text-decoration: none !important;
    outline: none !important;
    -webkit-transition: all .3s ease-in-out !important;
    -moz-transition: all .3s ease-in-out !important;
    -ms-transition: all .3s ease-in-out !important;
    -o-transition: all .3s ease-in-out !important;
    transition: all .3s ease-in-out !important;
}


.footer-component :focus {
    outline: 0 !important;
}

.footer-component .btn-custom {
    margin-top: 20px !important;
    background-color: transparent !important;
    border: 2px solid #ddd !important;
    padding: 12px 40px !important;
    font-size: 16px !important;
}

.footer-component .lead {
    font-size: 18px !important;
    line-height: 30px !important;
    color: #767676 !important;
    margin: 0 !important;
    padding: 0 !important;
}

.footer-component .form-control:focus {
    border-color: #ffffff !important;
    box-shadow: 0 0 0 .2rem rgba(255, 255, 255, .25) !important;
}

.footer-component .navbar-form input {
    border: none !important;
}

.footer-component .badge {
    font-weight: 500 !important;
}

.footer-component blockquote {
    margin: 20px 0 20px !important;
    padding: 30px !important;
}

.footer-component button {
    border: 0 !important;
    margin: 0 !important;
    padding: 0 !important;
    cursor: pointer !important;
}

.footer-component .full {
    float: left !important;
    width: 100% !important;
}

.footer-component .layout_padding {
    padding-top: 90px !important;
    padding-bottom: 90px !important;
}

.footer-component ul.btn {
    float: right !important;
}

.footer-component ul.btn li {
    display: inline-block !important;
    padding: 0 25px !important;
}

.footer-component ul.btn li a {
    color: #fff !important;
    font-size: 16px !important;
}

.footer-component ul.btn li:last-child {
    padding-right: 0 !important;
}

.footer-component .layout_padding_2 {
    padding-top: 75px !important;
    padding-bottom: 75px !important;
}

.footer-component .light_silver {
    background: #f9f9f9 !important;
}

.footer-component .theme_bg {
    background: #38c8a8 !important;
}

.footer-component .margin_top_30 {
    margin-top: 30px !important;
}

.footer-component .full {
    width: 100% !important;
    float: left !important;
    margin: 0 !important;
    padding: 0 !important;
}


/** footer **/

.footer-component .footer {
    background-repeat: no-repeat !important;
    background-size: 100% 100% !important;
    padding-top: 60px !important;
    text-align: center !important;
}

.footer-component .footer .titlepage {
    text-align: left !important;
    padding-bottom: 0 !important;
}

.footer-component .footer .titlepage h2 {
    color: #fff !important;
    margin: inherit !important;
    border-bottom: #fff solid 1px !important;
}

.footer-component .logo1 {
    float: left !important;
    margin-top: -30px !important;
}

.footer-component ul.social_icon {
    padding-top: 37px !important;
    width: 100% !important;
    float: left !important;
    text-align: left !important;
    margin-left: 20px !important;
}
.twitter_icon{
    color: black;
}
.twitter_icon1{
    margin: 0px 0px 1px 0px;
}

.footer-component ul.social_icon li {
    display: inline-block !important;
}

.footer-component ul.social_icon li a {
    background: #fff !important;
    width: 40px !important;
    height: 40px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    font-size: 25px !important;
    border-radius: 30px !important;
    margin: 0 3px !important;
}

.footer-component ul.social_icon li a:hover {
    background-color: #48ca95 !important;
    color: #fff !important;
}

.footer-component .footer h3 {
    font-weight: 600 !important;
    font-size: 25px !important;
    line-height: 25px !important;
    text-align: left !important;
    color: #fff !important;
    padding-bottom: 15px !important;
}

.footer-component .about_us li {
    color: #fff !important;
    font-size: 17px !important;
    text-align: left !important;
}

.footer-component .conta li {
    color: #fff !important;
    font-size: 17px !important;
    text-align: left !important;
}

.footer-component .bottom_form h3 {
    font-weight: 600 !important;
    font-size: 25px !important;
    line-height: 25px !important;
    text-align: left !important;
    color: #fff !important;
    padding-bottom: 15px !important;
}

.footer-component .bottom_form .enter {
    border: inherit !important;
    padding: 0 19px !important;
    width: 100% !important;
    height: 45px !important;
    background: #fffffff2 !important;
    color: #ccc8c8 !important;
    font-size: 17px !important;
    font-weight: 600 !important;
    float: left !important;
    border-radius: 10px !important;
    margin-bottom: 20px !important;
}

.footer-component .sub_btn {
    font-size: 17px !important;
    transition: ease-in all 0.5s !important;
    background-color: #78c7d2 !important;
    color: #fff !important;
    padding: 10px 0 !important;
    max-width: 147px !important;
    border-radius: 10px !important;
    width: 100% !important;
    display: block !important;
    font-weight: 500 !important;
    text-transform: uppercase !important;
}

.footer-component .sub_btn:hover {
    background-color: #000 !important;
    transition: ease-in all 0.5s !important;
    color: #fff !important;
}


.footer-component .copyright {
    margin: 60px 0 -40px 0 !important;
    padding-bottom: 20px !important;
}

.copyrights {
    font-size: 21px;
}

.footer-component .copyright p {
    color: #ddd !important;
    font-size: 18px !important;
    line-height: 22px !important;
    text-align: center !important;
    border-top: #ddd solid 1px !important;
    padding-top: 25px !important;
    font-weight: normal !important;
}

.footer-component .copyright a {
    color: #ddd !important;
}

.footer-component .copyright a:hover {
    color: #48ca95 !important;
}


/** end footer **/
.footer-component a:hover {
    color: rgb(98, 77, 182) !important;
    cursor: pointer !important;
}

/** inner page css **/

.footer-component .inner_posituong .header {
    position: inherit !important;
    background: #4843a3 !important;
}

.footer-component .computer_page .laptop {
    margin-top: 90px !important;
}

.footer-component .logo1 {
    margin: 0 0 -25px 28px !important;
}

.footer-component .contact_page .contact {
    margin-top: 90px !important;
}


/** about **/

.footer-component .about {
    background-color: #fff !important;
    padding: 90px 0 !important;
}

.footer-component .about .titlepage {
    text-align: left !important;
}

.footer-component .about .titlepage h2 {
    max-width: inherit !important;
    border: inherit !important;
}

.footer-component .about .titlepage p {
    padding: 30px 0 !important;
}

.footer-component .about_img figure {
    margin: 0 !important;
}

.footer-component .about_img figure img {
    width: 100% !important;
}


/** end about **/


/** laptop **/

.footer-component .laptop1 {
    background-color: #f6f6f6 !important;
    padding: 90px 0 !important;
}

.footer-component .laptop1 .titlepage {
    text-align: right !important;
}

.footer-component .laptop1 .titlepage h2 {
    max-width: inherit !important;
    border: inherit !important;
    color: #000 !important;
}

.footer-component .laptop1 .titlepage p {
    padding: 30px 0 !important;
    color: #000 !important;
}

.footer-component .laptop1_img figure {
    margin: 0 !important;
}

.footer-component .laptop1_img figure img {
    width: 100% !important;
}

.flex-mess {

    gap: 43px;
}

/** end laptop **/

