.adminpage {
    height: 850px;
    display: flex;
}

.admindashboard {
    height: 1500px;
    display: flex;
}

.socialmediaTable {
    margin: 25px;
    padding: 10px;
}

.test {
    height: 850px;
    display: flex;
}

.subscribedadmin {
    height: 1250px;
    display: flex;
}

.body-admin {
    display: flex;

}

.waitinglistadmin {

    display: flex;
}

.sidebarsdata {
    width: 200px;
    float: left;
    background-color: #232e44;

    margin-top: 13px;
}

.topics-mains {
    color: #d3cfdc;
    left: 16px;
    position: relative;
    top: 50px;
    font-size: 23px;
}

.a-topicss {
    color: white;
    left: 16px;
    position: relative;
    top: 50px;
    font-size: 20px;
}

.active-link {
    position: absolute;
    bottom: -15px;
    left: 0;
    right: 0;
    border-bottom: 4px solid var(--primary);
    border-radius: 10px;
    z-index: 10;
    --primary: #FF2D55;
    width: 60px;
}

.navs-menu.active {
    left: 0;
    transition: 350ms;
}

.a-topics {
    cursor: pointer;
    text-decoration: none;
}

.a-topics:hover {
    color: #736e7d;
}

.topics {
    padding-inline-start: 20px !important;
    text-align: left;
}

.topic-main {
    font-weight: 600;
    display: flex;
}

@media screen and (max-width: 768px) {
    .navs-menu.active {
        margin-top: 190px;
    }
}

.fa-trash-cans {
    cursor: pointer;
}

.fa-pen {
    cursor: pointer;
}

.text-areas {
    position: relative;

    width: 100%;
    float: right;
}

.ql-container {
    height: 255px !important;
}

.butti-send {
    top: 5px;
    float: right;
    position: relative;
}

.box-chart {
    height: 640px !important;
    width: 84% !important;
    top: 110px;
    position: relative;
}

.box-charts {
    height: 422px !important;
    width: 85% !important;
    margin-top: 2.5%;
    position: relative;
    background: linear-gradient(81.62deg, #313860 2.25%, #151928 79.87%);
    margin-left: 5%;
}

.box-chart {
    background: linear-gradient(81.62deg, #313860 2.25%, #151928 79.87%);
}

.box-charts2 {
    height: 422px !important;
    width: 85% !important;
    position: relative;
    background: linear-gradient(81.62deg, #313860 2.25%, #151928 79.87%);
    margin-left: 5%;
    margin-top: 0.5%;
}

.h1-topic-me {
    top: 30px;
    position: relative;
    margin-top: 0%;
}

.h1s-topic-me {
    margin-left: 5%;
    width: 85%;
}

.organizzme {
    display: flex;
    flex-direction: column;
    width: 85%;
}

.box-boxs {
    height: 370px !important;
    margin-top: -47px;
}


.optionsss {
    position: relative;
    top: 47px;
}

.guide-chart {
    position: relative;
    text-align: left;
    margin: 0px;
    font-size: 10px;
    color: white;
    width: 200px;
    margin-left: 25px;
    top: -2px;
}

.check-box-chart {
    width: 10px;
    height: 10px;
    margin-top: 3px;
    margin-left: 10px;
    position: relative;
    top: -45px;
    border: solid 1px #fff;
}

.p-chart {
    margin-top: 0px;
}

.first-guide {
    background-color: #3182CE;
}

.second-guide {
    background-color: #31ce9a;
}

.third-guide {
    background-color: white;
}

.select-lable-chart {
    position: relative;
    display: block;
    width: 144px;
    margin: 0 auto;
    border: 1px solid #3C1C78;
    background: linear-gradient(81.62deg, #313860 2.25%, #151928 79.87%);
    z-index: 10;
}

.select-chart {
    outline: none;
    background: transparent;
    appearance: none;
    border-radius: 0;
    margin: 0;
    display: block;
    width: 100%;
    padding: 3px 55px 3px 4px;
    font-size: 14px;
    color: #fff;
    font-weight: 800;
}

.select-lable-chart::after {
    position: absolute;
    right: 0;
    top: 0;
    width: 50px;
    height: 100%;
    line-height: 22px;
    content: "∨";
    text-align: center;
    color: #714BB9;
    font-size: 22px;
    border-left: 1px solid #3C1C78;
    z-index: -1;
}

.select-chart option {
    color: black;
}
