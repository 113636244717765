image_back { 
    background: url(../../assets/images/01_12.png) no-repeat center center fixed; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
.modal-title {
  text-align: center !important;
}
.modal-dialog {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(1) !important;
  position: absolute !important;
  width: 406px !important;
  margin-right: auto !important;
  margin-left: auto !important;
  text-align: center !important;
}
.bgsc{
  background-size: cover !important;
  margin-top: -80px;
  background: url('../../assets/images/01_12.png');
}
@media screen and (max-width:407px){
  .modal-dialog {
    width: 100% !important;
  }
}
.modal-content {
  padding: 0 30px 30px !important;
}
.modal-content h2{
  font-size: 38px;
  font-weight: 500 !important;
  margin: 30px 0 10px;
}
.imgs {
    width: 100px;
    margin-top: -50px;
    border-radius: 50%;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    margin-left: auto;
    margin-right: auto;
}
.headers{
    font-size: 38px;
    font-weight: 500;
    margin: 30px 0 10px;
}
.buttons {
    width: 65%;
    margin-top: 50px;
    padding: 10px 0;
    background: #6a2bbd;
    color: #fff;
    border: 0;
    outline: none;
    font-size: 18px;
    border-radius: 4px;
    cursor: pointer;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
    transition: all .3s ease-in-out;
}
.buttonse {
  width: 30%;
  margin-top: 50px;
  padding: 10px 10px;
  background: #fff;
  color: black;
  font-weight: bold;
  border: 0;
  outline: none;
  font-size: 18px;
  border-radius: 4px;
  cursor: pointer;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
  margin: 0px 5px -43px 0px;
  transition: all .3s ease-in-out;
}
.buttonse:hover {
  background: #e4d6d6;
}
.buttons:hover {
  background: #390083;
}
  .btn-closee {
    position: fixed;
    background-color: #fff;
    top: 5px;
    right: calc(25% - 95px);
    border: 0px;
  }
  .signup-opti{
    display: flex;
  }
  .img-log{
    height: 525px;
    width: 400px;
  }
  .butt-log{
    position: relative;
    background: none;
    border: none;
    transition: all 0.3s ease 0s;
    width: 0px;
    height: 0px;
  }
  .butt-log:hover{
    transform: translateY(-7px);
  }
  .modidi{
    flex-direction: column;
    padding: 0.75rem 0px 0.75rem 0px !important;
  }
  .jesss{
    margin-top: 25px;
  }
  @media screen and (max-width:385px){
    .btn-closee {
      right: calc(25% - 89px);
    }
  }
  @media screen and (max-width:356px){
    .btn-closee {
      right: calc(25% - 82px);
    }
  }
  @media screen and (max-width:333px){
    .btn-closee {
      right: calc(27% - 75px);
    }
  }
  @media screen and (max-width:304px){
    .btn-closee {
      right: calc(27% - 75px);
    }
  }
  @media screen and (max-width:280px){
    .btn-closee {
      right: calc(29% - 75px);
    }
  }
  @media screen and (max-width:264px){
    .btn-closee {
      right: calc(31% - 75px);
    }
  }
  @media screen and (max-width:244px){
    .btn-closee {
      right: calc(33% - 75px);
    }
  }
  .MuiSvgIcon-root {
    fill: currentColor;
    width: 1em;
    height: 1em;
    display: inline-block;
    font-size: 1.5rem;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    flex-shrink: 0;
    user-select: none;
  }
  .inputs{
    border: 1px solid #B7B7B7;
    width: 100%;
    padding: 13px 12px; 
    box-sizing: border-box;
    border-radius: 4px;
  }
  .changed-mes{
    height: 52px;
    width: 317px;
    z-index: 1;
    position: relative;
    top: 156px;
    left: -364px;
    background: #5f3be2;
    border-radius: 25px;
  }
  .p-buton-log1{
    margin: 0px;
    top: 18px;
    color: white;
    position: relative;
  }
  @media screen and (max-width:991px){
    .mmpop{
      margin-top: 40px !important;
    }
  }
  @media screen and (min-width:991px){
    .bgsc{
      height: 100vh;
    }
  }
  @media screen and (max-width:428px){
    .changed-mes{
      top: -121px;
    left: -158px;
    }
  }
  @media screen and (max-width:417px){
    .changed-mes{
      top: -119px;
      left: -100px;
      height: 51px;
    }
  }
  