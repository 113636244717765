.product--image {
    width: 100%;
    height: 16em;
    object-fit: cover;
  }
  
  .card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    width: 320px;
    margin: auto 1rem;
    text-align: center;
    font-family: arial;
  }
  .cardss{
    display: flex;
    justify-content: center;
    border-radius: 28px 28px 0.375rem 0.375rem !important;
    height: fit-content;
    border: 0 !important;
    box-shadow: 0 2px 15px -3px rgba(0,0,0,.07), 0 10px 20px -2px rgba(0,0,0,.04) !important;
  }
  .imgopt{
    border-radius: 28px;
  }
  .btnopt{
    width: fit-content;
    background-color: #721d85;
  }
  .price {
    color: grey;
    font-size: 22px;
  }
  .customize-me::-webkit-scrollbar {
    background-color: white;
    width: 12px;
  }
  .customize-me::-webkit-scrollbar-thumb{
    background:rgb(51, 21, 64);
    border-radius: 20px;
  }
  .customize-me::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}
  /* .card button {
    border: none;
    outline: 0;
    padding: 12px;
    color: white;
    background-color: #000;
    text-align: center;
    cursor: pointer;
    width: 100%;
    font-size: 18px;
  } */
  
  .card button:hover {
    opacity: 0.7;
  }
  .react-multi-carousel-item {
    transform-style: preserve-3d;
    backface-visibility: hidden;
    display: flex;
    justify-content: center;
}