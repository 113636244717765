.aiAgent-component {
    background: #602ea9;
    padding: 20px;
    text-align: left;

}

.ai-info-container {
    background:white;
    box-shadow: inset 0px -20px 20px 0px #6e53c5;
    /*background: white;*/
    padding: 30px;
    border: #331540;
    margin: 10px;
    border-radius: 20px;
}


.ai-env-container {
    border: 2px;
    margin-top: 30px;
    padding: 5px;

}
.ai-faq-title{
    margin-left: 20px;
}
.ai-faq-title img{
    width: 78px;
    height: 78px;
    top: 7px;
    position: relative;
}
.ai-faq-container {

    margin: 10px;
    border-radius: 25px;
    background: #E4DAD9;
}
.ai-faq .accordion-header{}
.ai-faq .accordion-item {
    margin: 10px;
    border-radius: 0px 0px 15px 15px;
    box-shadow: inset 0px -10px 20px 0px #6e53c5;


}