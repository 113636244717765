.modal-title {
    text-align: center !important;
  }
  .modal-dialog {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(1) !important;
    position: absolute !important;
    width: 406px !important;
    margin-right: auto !important;
    margin-left: auto !important;
    text-align: center !important;
  }
  .optsmess{
    width: 100%;
}
.a-bt{
  width: 100%;
}
.h6-log{
  margin-top: 40px;
}
  @media screen and (max-width:407px){
    .modal-dialog {
      width: 100% !important;
    }
  }
  .modal-content {
    padding: 0 30px 30px !important;
  }
  .modal-content h2{
    font-size: 38px;
    font-weight: 500 !important;
    margin: 30px 0 10px;
  }
  .imgs {
      width: 100px;
      margin-top: -50px;
      border-radius: 50%;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
      margin-left: auto;
      margin-right: auto;
  }
  .headers{
      font-size: 38px;
      font-weight: 500;
      margin: 30px 0 10px;
  }
  .buttonses {
      width: 100%;
      margin-top: 20px;
      padding: 10px 0;
      background: #6a2bbd;
      color: #fff;
      border: 0;
      outline: none;
      font-size: 18px;
      border-radius: 4px;
      cursor: pointer;
      box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
      transition: all .3s ease-in-out;
  }
  .buttonsse {
    width: 100%;
    margin-top: 50px;
    padding: 10px 10px;
    background: #fff;
    color: black;
    font-weight: bold;
    border: 0;
    outline: none;
    font-size: 18px;
    border-radius: 4px;
    cursor: pointer;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
    margin: 0px 5px 0px 0px;
    transition: all .3s ease-in-out;
  }
  .buttonsse:hover {
    background: #e4d6d6;
  }
  .buttonses:hover {
    background: #390083;
  }
    .btn-closee {
      position: fixed;
      background-color: #fff;
      top: 5px;
      right: calc(25% - 95px);
      border: 0px;
    }
    @media screen and (max-width:385px){
      .btn-closee {
        right: calc(25% - 89px);
      }
    }
    @media screen and (max-width:356px){
      .btn-closee {
        right: calc(25% - 82px);
      }
    }
    @media screen and (max-width:333px){
      .btn-closee {
        right: calc(27% - 75px);
      }
    }
    @media screen and (max-width:304px){
      .btn-closee {
        right: calc(27% - 75px);
      }
    }
    @media screen and (max-width:280px){
      .btn-closee {
        right: calc(29% - 75px);
      }
    }
    @media screen and (max-width:264px){
      .btn-closee {
        right: calc(31% - 75px);
      }
    }
    @media screen and (max-width:244px){
      .btn-closee {
        right: calc(33% - 75px);
      }
    }
    .MuiSvgIcon-root {
      fill: currentColor;
      width: 1em;
      height: 1em;
      display: inline-block;
      font-size: 1.5rem;
      transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      flex-shrink: 0;
      user-select: none;
    }
    .inputs{
      border: 1px solid #B7B7B7;
      width: 100%;
      padding: 13px 12px; 
      box-sizing: border-box;
      border-radius: 4px;
    }