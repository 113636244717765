/*--------------------------------------------------------------------- File Name: responsive.css ---------------------------------------------------------------------*/


/*------------------------------------------------------------------- 991px x 768px ---------------------------------------------------------------------*/

@media (min-width: 1200px) and (max-width: 1342px) {
    .navigation.navbar-dark .navbar-nav .nav-link {
        padding: 0px 20px;
    }
    .text-bg span {
        font-size: 41px;
    }
    .laptop_box {
        margin-right: 0px;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .navigation.navbar-dark .navbar-nav .nav-link {
        padding: 0px 11px;
    }
    .text-bg h1 {
        font-size: 56px;
        line-height: 66px;
        padding-bottom: 21px;
    }
    .text-bg span {
        font-size: 31px;
        line-height: 42px;
    }
    .text-bg a {
        max-width: 163px;
    }
    .laptop .titlepage h2 {
        font-size: 78px;
    }
    .laptop_box {
        margin-right: 0px;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .navigation.navbar-dark .navbar-nav .nav-link {
        padding: 0px 9px;
    }
    .banner_main {
        min-height: 705px;
    }
    .text-bg h1 {
        font-size: 40px;
        line-height: 65px;
        padding-bottom: 10px;
    }
    .text-bg span {
        font-size: 21px;
        line-height: 23px;
    }
    .text-bg a {
        max-width: 107px;
        padding: 9px 0px;
    }
    .d_none {
        display: none;
    }
    .laptop_box {
        margin-right: 0px;
    }
    .box_text p {
        font-size: 15px;
        line-height: 23px;
    }
    .laptop .titlepage p {
        font-size: 30px;
        line-height: 36px;
    }
    .laptop .titlepage h2 {
        font-size: 56px;
        line-height: 66px;
    }
    .about_us li {
        margin-bottom: 30px;
    }
}

@media (min-width: 576px) and (max-width: 767px) {
    .text-bg h1 {
        font-size: 70px;
        line-height: 40px;
        padding-bottom: 25px;
    }
    #banner1 .carousel-inner {
        padding-right: 0;
    }
    .text_img {
        margin-right: 0px;
    }
    #banner1 .carousel-indicators {
        bottom: -40px;
    }
    .text-bg span {
        font-size: 38px;
    }
    .text-bg a {
        margin-bottom: 30px;
        max-width: 202px;
    }
    .box_text {
        margin-bottom: 30px;
    }
    .bottom_form h3,
    .footer h3 {
        margin-top: 30px;
    }
    .laptop_box {
        margin-right: 0px;
        margin-top: 30px;
    }
    .main_form {
        margin: 0;
    }
    ul.location_icon li {
        font-size: 17px;
        padding-left: 13px;
    }
    .navigation.navbar {
        float: right;
        display: inherit !important;
        padding: 0;
        width: 100%;
        padding-top: 12px;
    }
    .navigation .navbar-collapse {
        background: #48ca95;
        padding: 20px;
        margin-top: 64px;
        position: absolute;
        width: 100%;
        margin-right: 20px;
        z-index: 999;
    }
    .navigation.navbar-dark .navbar-nav .nav-link {
        padding: 10px 0;
        color: #000;
    }
    .navigation.navbar-dark .navbar-nav .nav-link:focus,
    .navigation.navbar-dark .navbar-nav .nav-link:hover {
        color: #fff;
    }
    .navigation.navbar-dark .navbar-nav .active>.nav-link,
    .navigation.navbar-dark .navbar-nav .nav-link.active,
    .navigation.navbar-dark .navbar-nav .nav-link.show,
    .navigation.navbar-dark .navbar-nav .show>.nav-link {
        color: #fff;
    }
    .navigation.navbar-dark .navbar-toggler {
        border: inherit;
        float: right;
        padding-top: 6px;
    }
    .navigation.navbar-dark .navbar-toggler-icon {
        background-repeat: no-repeat;
    }
    .d_none {
        display: none;
    }
}

@media (max-width: 575px) {
    .header {
        padding: 30px 0px 15px 0px;
    }
    .logo {
        text-align: center !important;
        padding-bottom: 6px;
        float: left;
    }
    #banner1 .carousel-inner {
        padding-right: 0;
    }
    .text_img {
        margin-right: 0px;
        margin-top: 30px;
    }
    #banner1 .carousel-indicators {
        bottom: -40px;
    }
    .text-bg h1 {
        font-size: 47px;
        line-height: 40px;
        padding-bottom: 10px;
    }
    .text-bg span {
        font-size: 25px;
        line-height: 47px;
    }
    .text-bg a {
        max-width: 132px;
        padding: 10px 0px;
    }
    .box_text {
        margin-bottom: 30px;
    }
    .laptop .titlepage p {
        font-size: 28px;
        line-height: 40px;
    }
    .laptop_box {
        margin-right: 0px;
        margin-top: 30px;
    }
    .titlepage h2 {
        font-size: 30px;
    }
    .main_form {
        margin: 0px;
        padding: 70px 30px;
    }
    .footer h3,
    .bottom_form h3 {
        margin-top: 30px;
    }
    .navigation.navbar {
        float: right;
        display: inherit !important;
        padding: 0;
        width: 100%;
        margin-top: -96px;
    }
    .navigation .navbar-collapse {
        background: #E4DAD9;
        padding: 20px;
        margin-top: 67px;
        position: absolute;
        width: 100%;
        margin-right: 20px;
        z-index: 999;
    }
    .navigation.navbar-dark .navbar-nav .nav-link {
        padding: 10px 0;
        color: #000;
    }
	#gold {
		color:#fff;
	}
    .navigation.navbar-dark .navbar-nav .nav-link:focus,
    .navigation.navbar-dark .navbar-nav .nav-link:hover {
        color: #4F30BF;
    }
    /*.navigation.navbar-dark .navbar-nav .active>.nav-link,
    .navigation.navbar-dark .navbar-nav .nav-link.active,
    .navigation.navbar-dark .navbar-nav .nav-link.show,
    .navigation.navbar-dark .navbar-nav .show>.nav-link {
    
    }*/
    .navigation.navbar-dark .navbar-toggler {
        float: right;
        margin-right: 2px;
        border: inherit;
    }
    .testimonial_box {
        padding: 0px 19px 40px 20px;
    }
    .navigation.navbar-dark .navbar-toggler-icon {
        background-repeat: no-repeat;
    }
    .d_none {
        display: none;
    }
}

