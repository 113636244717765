
.custom-list {
    list-style-type: square;
    list-style-position: inside;
}
.seclectionText{
    color: #6B6B6B;
    font-size: 24px;
}

/* RadioImageButton.css */
.radio-image {
    display: block;
    position: relative;
    cursor: pointer;
    margin-bottom: 10px;
}

.radio-image input[type="radio"] {
    display: none; /* Hide the default radio button */
}

.radio-image img {
    width: 216px; /* Set the width and height of your image */
    height: 156px;
    border: 2px solid #ccc; /* Add a border to make it look like a button */
    border-radius: 25px; /* Make it round */
    transition: border-color 0.3s ease-in-out;
}

.radio-image input[type="radio"]:checked + img {
    border-color: #007bff; /* Change border color when selected */

}
