.upladFiletnx{
    padding-top: 30px ;
    display: flex;
    text-align: justify;
    padding-bottom: 50px;
}
.upladFiletnx li{
    list-style: disc !important;

}
.uploadFile .form-control{
 border: 0px;
    color:#2400FF ;
}