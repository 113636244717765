/* Ensures the modal dialog is correctly sized and centered */
.modal-main-title {
    font-size: 2rem; /* Adjust the size as needed */
    margin: 0; /* Remove default margin */
}

h2.modal-sub-title {
    font-size: 18px !important; /* Adjust the size as needed */
    margin: 0; /* Remove default margin */
    color: #a9a9a9 !important; /* Optional: Change the color to differentiate */
}
.custom-modal-style .modal-dialog {

    max-width: 80%; /* Adjust the modal width as necessary */

    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100px; /* Set the height of the modal */
    overflow: hidden; /* Hide overflow to force use of internal scrollbar */
}

/* Ensures the modal content fits within the dialog and scrolls as needed */
.custom-modal-style .modal-content {
    background-color: #2F2F2F; /* Dark gray background */
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    font-size: larger;
}

/* Styling for the modal header */
.custom-modal-style .modal-header {
    background-color: #2F2F2F; /* Consistent dark gray background */
    color: darkgrey; /* Text color for header */
}

/* Styling for the modal body */
.custom-modal-style .modal-body {
    padding: 20px; /* Padding for content inside modal body */
}

/* Custom scrollbar styles */
.custom-modal-style .modal-content::-webkit-scrollbar {
    width: 10px; /* Scrollbar width */
}

.custom-modal-style .modal-content::-webkit-scrollbar-track {
    background: #f1f1f1; /* Light gray track */
}

.custom-modal-style .modal-content::-webkit-scrollbar-thumb {
    background: #888; /* Darker thumb for visibility */
    border-radius: 5px; /* Rounded thumb for better grip visually */
}

.custom-modal-style .modal-content::-webkit-scrollbar-thumb:hover {
    background: #555; /* Darker on hover for interaction feedback */
}


.required {
    color: white; /* Sets the color for indicating required fields */
}

.btn-book-demo{
    flex: 1 1 auto;
    text-align: center;
    border-radius: 40px;
    font-family: "Sofia Pro",sans-serif;
    border: none;
    cursor: pointer;
    text-transform: uppercase;
    outline: none;
    overflow: hidden;
    position: relative;
    color: black;
    font-weight: 700;
    font-size: 18px;
    background: #78c7d2;
    padding:5px 20px;
    margin:  10px;
    width:max-content;
    /*box-shadow: 0 0.313rem 0.938rem #0003;*/
    z-index: 3;
}
